.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.listStyle   {
  text-decoration: none;
  font-size: 14px;
  margin:0 18px;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;

  line-height: 50px;

}

.resume {
  background:#32CD32;
  color: white;
  padding: 0 15px;
  @media (max-width: 460px) {
    background: transparent;
    color: #141414;
    padding: 0;
  }

}



.expereinceSection {
  padding-top: 130px;
  text-align: left;
  @media (max-width: 460px) {
    padding-top: 100px;
  }
}


.activeList {
  background:#32CD32;
  border-radius: 4px;
  color: white;
  list-style: none;
}





.hideDec li {
  cursor: pointer;
  display: inline-block;
  line-height: 50px;
  padding: 0 10px;
}

.hideDec li:hover {
  background-color: #32CD32;
  color: white;
  border-radius: 4px; 
}

.slideContent .text {
  width: 35%;
  display: inline-block;
  vertical-align: top;


  text-align: left;
  @media (max-width: 1260px) {
    width: 100%;
  }

}

.slideNav {
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1260px) {
    width: 100%;
  }
  @media (max-width: 460px) {
    display: block !important;
    border-bottom: none;

  }
}

.home {
  @media (max-width: 460px) {
    display: none;
  }
}