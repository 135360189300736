
.navStyle {
    display: block;
    background: white;
    align-items: center;
    overflow: hidden;
    width: 100%;
    margin: auto;
    top: 0;
    position: fixed;
    z-index: 9999;
    box-shadow: 0px 3px 3px 0px rgba(20,20,20,0.45);
    color: #141414;
    @media (max-width: 460px) {
        position:sticky;
        top: 0;
        z-index: 99999;

      }
    ul {
        float: right;
        padding-right: 30px;
        @media (max-width: 1260px) {
            padding: 0;
            width: 100%;

          }
      
      }
  }

  #top {
    @media (max-width: 460px) {
      position:sticky;
      top: 0;
      z-index: 99999;

    }
  }
