@import './src/scss/navigation.scss';
@import './src/scss/home.scss';
@import './src/scss/experience.scss';
@import './src/scss/about.scss';






body {
  font-family: poppins;
  height: 100%;
  margin: 0;
  color: white;
  background: rgb(237,120,69);
  background: linear-gradient(47deg, rgba(237,120,69,1) 25%, rgba(249,92,164,1) 54%, rgba(237,120,69,1) 100%);

}

