.experienceStyle {
    background-color: #141414;
    color: white;
    width: 95%;
    margin: 0 auto;
    border-radius: 10px 10px 0px 0px;
    min-height: 100vh;
    h1 {
        font-size: 58px;
        width: auto;
        display: inline-block;
        text-align: left;
        margin: 25px;
         @media (max-width: 1260px) {
            width: auto;
            text-align: left;
            font-size: 28px;
            margin-left: 15px;
            margin-bottom: 0px;

          }
          @media (max-width: 460px) {
              margin-bottom: 15px;
          }
    }
    h3 {
            font-size: 24px;
            margin-top: 0px;
            width: 90%;
            margin-left: 25px;
            @media (max-width: 460px) {
                font-size: 21px;
                margin: 0 auto;

            }

    }
    h2 {
        font-size: 36px;
        margin-left: 25px;
        text-decoration: underline;
    }
 
}
  
.img {
    width: 58%;
    height: auto;
    @media (max-width: 1260px) {
        width: 90%;
      }
      @media (max-width: 460px) {
          display: block;
          margin: 0 auto;

      }
}


.hideDec {
    display: inline-block;
    text-align: right;
    height: 50px;
    width: 70%;
    margin-top: 35px;
    margin-right: 25px;
    @media (max-width: 460px) {
        width: 95%;
        border-bottom: 2px solid white;
        display: block !important;
        border-top: 2px solid white;
        padding-top: 10px;
        margin: 0 auto;
        height: auto;
        padding-left: 0;

    }
    li {
        display: inline-block;
        cursor: pointer;
        font-size: 14px;
        margin-right: 15px;

            li:hover {
            background-color: #32CD32;
            color: white;
            border-radius: 4px; 
            }
            @media (max-width: 460px) {
                margin: 0px;
                width: 95%;
                text-align: left;
            }
        }
  
    }


.slideContent {
    margin-top: 50px;
    @media (max-width: 460px) {
        margin-top: 0px;
    }
    .contain {
        display: flex;
        justify-content: space-between;
        padding: 0 25px 50px 15px;
        margin-bottom: 25px;
        border-bottom: 2px dashed white;
        @media (max-width: 460px) {
            display: block;
 
        }
    }
}


.containerSlide  {
    width: 56%;
    @media (max-width: 460px) {
        width: 95%;
        margin: 0 auto;
        display: block;
    }
    display: inline-block;
    vertical-align: top;

}
  
 
  
//   .slideContent .text {
//     width: 35%;
//     display: inline-block;
//     vertical-align: top;
//     padding: 0 25px;
//     margin-top: 25px;
//     text-align: left;
  
//   }
  
//   .slideNav {
//     width: 50%;
//     display: inline-block;
//     vertical-align: text-bottom;
//     font-size: 14px;
//   }



